import { Title } from "@biblioteksentralen/cordata";

export const isbdFormattedTitle = (title: Title | undefined, includePartTitles = true) => {
  if (!title) return undefined;

  const { mainTitle, subtitle, partNumber, partTitle } = title;
  const subtitleSegment = subtitle ? ` : ${subtitle}` : "";
  const titleWithSubtitle = `${mainTitle}${subtitleSegment}`;

  if (!includePartTitles) return titleWithSubtitle;

  const partNumberSegment = partNumber ? `. ${partNumber}` : "";
  const partTitleSegment = partTitle ? `. ${partTitle}` : "";

  return `${titleWithSubtitle}${partNumberSegment}${partTitleSegment}`;
};

export const havePartTitles = ({ partTitle, partNumber }: Title) => !!partTitle || !!partNumber;

export const haveSamePartTitlesSctructure = (leftTitle: Title, rightTitle: Title) =>
  havePartTitles(leftTitle) === havePartTitles(rightTitle);
