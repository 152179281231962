import { AdventCalendar, Event, Library, Publication } from "./libryContentSanityTypes";
import { ArrayElement } from "./typeUtils";

export type CustomizableRepeatedEventField = Extract<
  keyof Event,
  "eventStart" | "eventEnd" | "eventStatus" | "teaser" | "body" | "featured"
>;

export const customizableRepeatedEventFields: CustomizableRepeatedEventField[] = [
  "eventStart",
  "eventEnd",
  "eventStatus",
  "teaser",
  "body",
];

export type AdventCalendarDoor = ArrayElement<NonNullable<AdventCalendar["doors"]>>;

export type AdventCalendarDoorType = AdventCalendarDoor["_type"];

export const isPublication = (item: unknown): item is Publication =>
  !!item && typeof item === "object" && item?.["_type"] === "publication";

export const isLibrary = (item: unknown): item is Library =>
  !!item && typeof item === "object" && item?.["_type"] === "library";
