import type { SanityClient, SanityDocumentStub } from "@sanity/client";
import { isSanityDocument, type ImageAsset } from "@sanity/types";
import fetch from "cross-fetch";
import chunk from "lodash/chunk";
import { uploadSanityImageAsset } from "./uploadSanityImageAsset";

const hasAsset = (item: unknown): item is { _sanityAsset: string } =>
  typeof item === "object" && typeof item?.["_sanityAsset"] === "string";

const parseAssetId = (sanityAsset: string) => {
  const id = sanityAsset.split("/").at(-1)?.split("-")[0];
  if (!id) throw new Error(`Could not parse asset id from "${sanityAsset}"`);
  return id;
};

const getAssetIds = (data: unknown, results: string[] = []): string[] => {
  if (!data) return results;
  if (hasAsset(data)) return [...results, parseAssetId(data._sanityAsset)];
  if (Array.isArray(data)) return [...results, ...data.flatMap((item) => getAssetIds(item))];
  if (typeof data === "object") return [...results, ...Object.values(data).flatMap((value) => getAssetIds(value))];
  return results;
};

const insertAssets = (data: unknown, allAssets: AssetMap): unknown => {
  if (!data) return data;

  if (hasAsset(data)) {
    const { _sanityAsset, ...rest } = data;
    if (!allAssets[parseAssetId(_sanityAsset)]) console.warn("🤮", { _sanityAsset, allAssets });
    return { ...rest, asset: { _type: "reference", _ref: allAssets[parseAssetId(_sanityAsset)] } };
  }

  if (Array.isArray(data)) return data.map((item) => insertAssets(item, allAssets));

  if (typeof data === "object") {
    return Object.fromEntries(Object.entries(data).map(([key, item]) => [key, insertAssets(item, allAssets)]));
  }

  return data;
};

type AssetMap = Record<ImageAsset["assetId"], ImageAsset["_id"]>;

const getAssetsFromDataset = async (sanityClient: SanityClient, assetIds: string[]): Promise<AssetMap> => {
  const assets = await sanityClient.fetch<Pick<ImageAsset, "_id" | "assetId">[]>(
    `*[assetId in $assetIds]{ _id, assetId }`,
    { assetIds }
  );

  return assets.reduce((acc, { _id, assetId }) => ({ ...acc, [assetId]: _id }), {});
};

const getS3FileUrl = (s3Host: string, s3Bucket: string, filePath: string) =>
  `https://${s3Host}/${s3Bucket}/${filePath}`;

const getS3ListUrl = (s3Host: string, s3Bucket: string, match: string) => {
  const url = new URL(`https://${s3Host}/storage/v1/b/${s3Bucket}/o`);
  url.searchParams.append("matchGlob", match);
  return url.toString();
};

const responseToBuffer = async (response: Response) => {
  const assetBlob = await response.blob();
  const arrayBuffer = await assetBlob.arrayBuffer();
  const buffer = Buffer.alloc(arrayBuffer.byteLength);
  const arrayBufferViews = new Uint8Array(arrayBuffer);
  arrayBufferViews.forEach((view, idx) => (buffer[idx] = view));

  return buffer;
};

type ImageNames = { name: string }[];

const hasImageNames = (data: unknown): data is { items: ImageNames } =>
  !!data &&
  typeof data === "object" &&
  Array.isArray(data?.["items"]) &&
  data?.["items"].every((item) => !!item && typeof item === "object" && typeof item?.["name"] === "string");

const getAssetFromS3 = async (
  s3Host: string,
  s3Bucket: string,
  sanityClient: SanityClient,
  id: string,
  imageNames: ImageNames
) => {
  const images3Name = imageNames.find(({ name }) => !!name.match(new RegExp(`.+\\/${id}.+\\.\\w+`)))?.name;
  if (!images3Name) throw new Error(`Could not find s3 name for image "${id}"`);

  const assetUrl = getS3FileUrl(s3Host, s3Bucket, images3Name);
  const assetResponse = await fetch(assetUrl);
  if (!assetResponse.ok) throw new Error(`Could get asset from ${assetUrl}: ${assetResponse.statusText}`);

  const assetBuffer = await responseToBuffer(assetResponse);
  const assetDocument = await uploadSanityImageAsset(sanityClient, assetBuffer);
  return assetDocument;
};

const getAssetsFromS3 = async (
  s3Host: string,
  s3Bucket: string,
  sanityClient: SanityClient,
  path: string,
  assetIds: string[]
): Promise<AssetMap> => {
  const imagesListUrl = getS3ListUrl(s3Host, s3Bucket, `${path}/images/*`);
  const imagesListResponse = await fetch(imagesListUrl);
  if (!imagesListResponse.ok) throw new Error(`Could get list from ${imagesListUrl}: ${imagesListResponse.statusText}`);

  const imagesList: unknown = await imagesListResponse.json();
  if (!hasImageNames(imagesList)) throw new Error("Could not parse images list from s3");

  // We chunk the assets to avoid rate limits
  const assetIdChunks = chunk(assetIds, 10);
  const assetMap: AssetMap = {};

  for (const chunkIds of assetIdChunks) {
    const documents = await Promise.all(
      chunkIds.map((id) => getAssetFromS3(s3Host, s3Bucket, sanityClient, id, imagesList.items))
    );
    documents.forEach(({ _id, assetId }) => (assetMap[assetId] = _id));
  }

  return assetMap;
};

/**
 * Get documents from a `path` to a directory within an s3 bucket set by the env.
 *
 * The directory should contain a data.ndson file and an images/ directory whose
 * image files are named using the asset ids, e.g. the contents of an unzipped
 * exported dataset except for the assets.json file.
 *
 * The image assets are added to the current dataset unless they already exist.
 */
export const getDocumentsFromS3 = async (
  s3Host: string,
  s3Bucket: string,
  sanityClient: SanityClient,
  path: string
): Promise<SanityDocumentStub[]> => {
  const dataUrl = getS3FileUrl(s3Host, s3Bucket, `${path}/data.ndjson`);

  const dataResponse = await fetch(dataUrl);
  if (!dataResponse.ok) throw new Error(`Could not get ${dataUrl}: ${dataResponse.statusText}`);
  const textData = await dataResponse.text();

  const jsonData: object[] = textData
    .split(/\r?\n/) // Parse newlines from both unix and windows
    .filter(Boolean)
    .map((text) => {
      try {
        return JSON.parse(text);
      } catch (err) {
        console.error(`Could not parse as json: "${text}"`);
        throw err;
      }
    });

  const assetIds = getAssetIds(jsonData);
  const assetsFromDataset = await getAssetsFromDataset(sanityClient, assetIds);
  const remainingAssetIds = assetIds.filter((id) => !(id in assetsFromDataset));
  const assetsFromS3 = await getAssetsFromS3(s3Host, s3Bucket, sanityClient, path, remainingAssetIds);

  const allAssets = { ...assetsFromDataset, ...assetsFromS3 };
  const missingAssetIds = assetIds.filter((id) => !(id in allAssets));
  if (missingAssetIds.length) throw new Error(`Could not get assets ${missingAssetIds}`);

  const documents = insertAssets(jsonData, allAssets);

  if (!Array.isArray(documents) || !documents.every(isSanityDocument)) {
    throw new Error("Something went wrong when getting documents from s3");
  }

  return documents;
};
