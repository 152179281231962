import { Contributor } from "@biblioteksentralen/cordata";
import { LanguageCode } from "@libry-content/localization";

export const contributorRoleLabels = [
  "annen rolle",
  "bearbeider",
  "beæret",
  "bokdesigner",
  "bokomslagsdesigner",
  "copyrightinnehaver",
  "designer",
  "dirigent",
  "eksamenssted",
  "forfatter",
  "fotograf",
  "idéskaper",
  "illustratør",
  "innleser",
  "instrumentalist",
  "intervjuer",
  "intervjuobjekt",
  "kolorist",
  "kommentator",
  "komponist",
  "konsulent",
  "kunstner",
  "kurator",
  "leder",
  "librettist",
  "manusforfatter",
  "medarbeider/bidragsyter",
  "medforfatter",
  "musikkarrangør",
  "opprinnelig forfatter",
  "oversetter",
  "prosjektleder",
  "redaktør",
  "regissør",
  "samlingsredaktør",
  "sanger",
  "sangtekstforfatter",
  "skuespiller",
  "utarbeider",
  "utgiver",
  "utøver",
  "vedtakende jurisdiksjon",
] as const;

export type ContributorRoleLabel = (typeof contributorRoleLabels)[number];

export const isContributorRoleLabel = (item: unknown): item is ContributorRoleLabel =>
  typeof item === "string" && (contributorRoleLabels as readonly string[]).includes(item);

// TODO: More?
const prioritizedContributorRoles: ContributorRoleLabel[] = [
  "forfatter",
  "kunstner",
  "oversetter",
  "regissør",
  "manusforfatter",
];

export const getContributorRoleSortIndex = (roleLabel: string) =>
  (prioritizedContributorRoles as string[]).includes(roleLabel)
    ? (prioritizedContributorRoles as string[]).indexOf(roleLabel)
    : prioritizedContributorRoles.length;

export const getContributorsWithRoles = (contributors: Contributor[], roleCodes: string[]): Contributor[] =>
  contributors.filter(({ roles }) => roles.some(({ code }) => roleCodes.includes(code)));

// TODO: Use code or id as keys?
export const contributorRoleLabelTranslations: Record<ContributorRoleLabel, Record<LanguageCode, string>> = {
  "annen rolle": { nb: "annen rolle", nn: "annan rolle" },
  bearbeider: { nb: "bearbeider", nn: "omarbeidar" },
  beæret: { nb: "beæret", nn: "æra" },
  bokdesigner: { nb: "bokdesigner", nn: "bokdesignar" },
  bokomslagsdesigner: { nb: "bokomslagsdesigner", nn: "bokomslagdesignar" },
  copyrightinnehaver: { nb: "copyrightinnehaver", nn: "copyrightinnehavar" },
  designer: { nb: "designer", nn: "designar" },
  dirigent: { nb: "dirigent", nn: "dirigent" },
  eksamenssted: { nb: "eksamenssted", nn: "eksamensstad" },
  forfatter: { nb: "forfatter", nn: "forfattar" },
  fotograf: { nb: "fotograf", nn: "fotograf" },
  idéskaper: { nb: "idéskaper", nn: "idéskapar" },
  illustratør: { nb: "illustratør", nn: "illustratør" },
  innleser: { nb: "innleser", nn: "innlesar" },
  instrumentalist: { nb: "instrumentalist", nn: "instrumentalist" },
  intervjuer: { nb: "intervjuer", nn: "intervjuar" },
  intervjuobjekt: { nb: "intervjuobjekt", nn: "intervjuobjekt" },
  kolorist: { nb: "kolorist", nn: "kolorist" },
  kommentator: { nb: "kommentator", nn: "kommentator" },
  komponist: { nb: "komponist", nn: "komponist" },
  konsulent: { nb: "konsulent", nn: "konsulent" },
  kunstner: { nb: "kunstner", nn: "kunstnar" },
  kurator: { nb: "kurator", nn: "kurator" },
  leder: { nb: "leder", nn: "leiar" },
  librettist: { nb: "librettist", nn: "librettist" },
  manusforfatter: { nb: "manusforfatter", nn: "manusforfattar" },
  "medarbeider/bidragsyter": { nb: "medarbeider/bidragsyter", nn: "medarbeidar/bidragsytar" },
  medforfatter: { nb: "medforfatter", nn: "medforfattar" },
  musikkarrangør: { nb: "musikkarrangør", nn: "musikkarrangør" },
  "opprinnelig forfatter": { nb: "opprinnelig forfatter", nn: "opphavleg forfattar" },
  oversetter: { nb: "oversetter", nn: "omsetjar" },
  prosjektleder: { nb: "prosjektleder", nn: "prosjektleiar" },
  redaktør: { nb: "redaktør", nn: "redaktør" },
  regissør: { nb: "regissør", nn: "regissør" },
  samlingsredaktør: { nb: "samlingsredaktør", nn: "samlingsredaktør" },
  sanger: { nb: "sanger", nn: "songar" },
  sangtekstforfatter: { nb: "sangtekstforfatter", nn: "songtekstforfattar" },
  skuespiller: { nb: "skuespiller", nn: "skodespelar" },
  utarbeider: { nb: "utarbeider", nn: "utarbeidar" },
  utgiver: { nb: "utgiver", nn: "utgjevar" },
  utøver: { nb: "utøver", nn: "utøvar" },
  "vedtakende jurisdiksjon": { nb: "vedtakende jurisdiksjon", nn: "vedtakande jurisdiksjon" },
};
