import { LanguageCode } from "@libry-content/localization";
import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import zonedTimeToUtc from "date-fns-tz/zonedTimeToUtc";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import isEqual from "date-fns/isEqual";

export type Weekday = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";

export const WEEKDAY_LABELS: Record<LanguageCode, Record<Weekday, string>> = {
  nb: {
    monday: "Mandag",
    tuesday: "Tirsdag",
    wednesday: "Onsdag",
    thursday: "Torsdag",
    friday: "Fredag",
    saturday: "Lørdag",
    sunday: "Søndag",
  },
  nn: {
    monday: "Måndag",
    tuesday: "Tysdag",
    wednesday: "Onsdag",
    thursday: "Torsdag",
    friday: "Fredag",
    saturday: "Laurdag",
    sunday: "Søndag",
  },
};

export const WEEKDAYS = Object.keys(WEEKDAY_LABELS.nb) as Weekday[];

export const isWeekday = (potentialWeekday: string): potentialWeekday is Weekday =>
  Object.keys(WEEKDAY_LABELS.nb).includes(potentialWeekday);

const NORWEGIAN_TIMEZONE = "Europe/Oslo";

export const norwegianDateToUTC = (date: Date | string) => zonedTimeToUtc(date, NORWEGIAN_TIMEZONE);

export const UTCDateToNorwegian = (date: Date | string) => utcToZonedTime(date, NORWEGIAN_TIMEZONE);

export const getNorwegianWeekday = (date: Date | string): Weekday => {
  const weekday = format(UTCDateToNorwegian(date), "iiii").toLowerCase();
  if (!isWeekday(weekday)) throw new Error("Could not get Norwegian weekday");
  return weekday;
};

export const getWeekdayIndex = (weekday: Weekday): number => WEEKDAYS.indexOf(weekday);

export const createConstantWeekObject = <T extends unknown>(dayValue: T): Record<Weekday, T> =>
  WEEKDAYS.reduce(
    (acc, weekday) => ({
      ...acc,
      [weekday]: dayValue,
    }),
    {} as Record<Weekday, T>
  );

export type Period = { from: string; isInterval?: boolean; to?: string };

export const getPeriodEnd = <T extends Period>({ from, isInterval, to }: T) => (isInterval ? to : from) as string;

export const isValidPeriod = <T extends Partial<Period>>(potentialPeriod: T): potentialPeriod is T & Period => {
  const { from, isInterval, to } = potentialPeriod;
  if (!from || (isInterval && !to)) return false;

  const fromDate = new Date(from);
  const toDate = new Date(getPeriodEnd(potentialPeriod as Period));

  return isEqual(fromDate, toDate) || isAfter(toDate, fromDate);
};

export const getPeriodAsDates = ({ from, isInterval, to }: Period): Date[] => {
  const fromDate = new Date(from!);
  return isInterval ? eachDayOfInterval({ start: fromDate, end: new Date(to!) }) : [fromDate];
};

export const SIMPLE_ISO_DATE_FORMAT = "yyyy-MM-dd";
export const SIMPLE_ISO_TIME_FORMAT = "HH:mm";

export const timeIsAfter = (time1: string, time2: string): boolean => {
  const [hours1, minutes1] = time1.split(":");
  const [hours2, minutes2] = time2.split(":");

  if (!hours1 || !minutes1 || !hours2 || !minutes2) return false;
  if (parseInt(hours1) > parseInt(hours2)) return true;
  return hours1 === hours2 && parseInt(minutes1) > parseInt(minutes2);
};

export const getNorwegianDateNow = () => format(utcToZonedTime(new Date(), NORWEGIAN_TIMEZONE), SIMPLE_ISO_DATE_FORMAT);
export const getNorwegianTimeNow = () => format(utcToZonedTime(new Date(), NORWEGIAN_TIMEZONE), SIMPLE_ISO_TIME_FORMAT);

export const isDecember = () => new Date().getMonth() === 11;
