import { filters } from "@libry-content/common";
import { LocalizedField } from "@libry-content/localization";
import { Event, Library, NullableArrayElement as ElementFromNullableArray } from "@libry-content/types";
import groq from "groq";

import { Modify } from "@libry-content/types";
import { EventOccurrence } from "./utils";

export const idOrKeyGroqQuery = groq`_type == "event" && (_id == $eventIdOrKey || $eventIdOrKey in repeatedDates[]._key)`;

export const groqEventStart = groq`select(allDay => '00:00', eventStart)`;

export const groqStartDate = groq`select(repeated => repeatedDates[@.date >= $today][0].date, startDate)`;

const upcomingOccurrence = groq`select(repeated => repeatedDates[@.date >= $today][0], null)`;

const lastOccurrence = `select(repeated => repeatedDates[@.date < $today][0], null)`;

export const resolveEventGroqProjectionCommon = groq`
  ...,
  'eventStart': ${groqEventStart},
  'eventType': eventType->label,
  'library': library->{name, _id, slug, _type}
`;

export type ResolvedEvent = Modify<
  Event,
  {
    eventType?: LocalizedField<string>;
    library?: Pick<Library, "name" | "_id" | "slug" | "_type">;
  }
>;

// It's tempting to expand repeated event dates here instead of in client code.
// In that case, however, we would need to also do the filtering in-query.
export const resolveEventGroqProjection = groq`
  ${resolveEventGroqProjectionCommon},
  'startDate': ${groqStartDate},
  'occurrence': ${upcomingOccurrence}
`;

export const resolvePastEventGroqProjection = groq`
  ${resolveEventGroqProjectionCommon},
  'startDate': select(repeated => repeatedDates[@.date < $today][0].date, startDate),
  'occurrence': ${lastOccurrence}
`;

// _id != $eventIdOrKey means that we are looking at a specific repeated event occurrence with _key = $eventIdOrKey
export const resolveSingleEventGroqProjection = groq`
  ${resolveEventGroqProjectionCommon},
  'startDate': select(repeated && _id != $eventIdOrKey => repeatedDates[@._key == $eventIdOrKey][0].date, ${groqStartDate}),
  'occurrence': select(repeated && _id != $eventIdOrKey => repeatedDates[@._key == $eventIdOrKey][0], ${upcomingOccurrence})
`;

const clientTimeOffsetHours = new Date().getTimezoneOffset() / -60;
export const clientTimeZone = `+${clientTimeOffsetHours.toString().padStart(2, "0")}:00`;

// The groq function dateTime() expects a certain ISO format
export const groqDateTime = (dateString: string): string =>
  groq`dateTime(${dateString} + "T00:00:00${clientTimeZone}")`;

// All-day events can have a startDate in the past be active today. When with comparing these to
// get the next event we should use a start date minimum from today
const groqDateClampToday = (date: string): string =>
  groq`select(${groqDateTime(date)} >= ${groqDateTime("$today")} => ${date}, $today)`;

const occurrenceStartDate = groq`select(repeated => occurrence.date, startDate)`;

// We can't filter on _id != ^._id because the same repeated event can have occurrences directly following each other.
// The definition of "occurrence" assumes that all-day events can not be repeated.
export const resolveNextEvent = groq`
  'nextEvent': *[_type == 'event' && defined(^.startDate) && ${filters.eventTodayOrLater}] {
    _id,
    _type,
    allDay,
    repeated,
    startDate,
    title,
    'eventStart': ${groqEventStart},
    "occurrence": select(
      repeated => repeatedDates[
        @.date > ${groqDateClampToday("^.^.startDate")} ||
        (@.date == ${groqDateClampToday("^.^.startDate")} && select(^.allDay => '00:00', ^.eventStart) > ^.^.eventStart)
      ][0], null),
  } [
    ${occurrenceStartDate} > ${groqDateClampToday("^.startDate")} ||
    (${occurrenceStartDate} == ${groqDateClampToday("^.startDate")} && eventStart > ^.eventStart)
  ] | order(${occurrenceStartDate} asc, eventStart asc)[0]
`;

export type NextEvent = Pick<Event, "_id" | "_type" | "allDay" | "repeated" | "startDate" | "title"> & {
  eventStart?: string;
  occurrence: ElementFromNullableArray<Event["repeatedDates"]>;
};

export type ResolvedEventWithNextEvent = EventOccurrence & { nextEvent?: NextEvent };
