import { Work } from "@biblioteksentralen/cordata";
import { isValidCoverImage } from "./coverImage";
import { handleLanguages } from "./languages";
import { filterManifestationsOnHoldings, DecoratedManifestation } from "./manifestations";

export type ImageManifestation = Pick<
  DecoratedManifestation,
  "id" | "title" | "coverImage" | "documentType" | "publicationYear" | "languages"
>;

/**
 * We filter out manifestations and expressions on library holdings. But we would like to be able
 * to display relevant cover images even though they are not part of the library holdings.
 * Therefore we store them separately in `allImages`.
 */
export const getAllWorkImages = <WorkType extends Partial<Work>>({
  expressions = [],
}: WorkType): ImageManifestation[] =>
  expressions.flatMap(({ manifestations = [], languages = [] }) =>
    manifestations
      .filter(({ coverImage }) => isValidCoverImage(coverImage))
      .flatMap(({ id, title, coverImage, documentType, publicationYear }) => ({
        id,
        title,
        coverImage,
        documentType,
        publicationYear,
        languages: handleLanguages()(languages),
      }))
  );

export const filterWorkDataOnHoldings =
  <WorkType extends Partial<Work>>(isilNumbers: string[] | null) =>
  (work: WorkType): WorkType => {
    if (!isilNumbers) return work;

    const expressions = work.expressions
      ?.map(({ manifestations = [], ...expression }) => ({
        ...expression,
        manifestations: filterManifestationsOnHoldings(manifestations, isilNumbers),
      }))
      .filter(({ manifestations = [] }) => manifestations.length > 0);
    return { ...work, expressions };
  };
