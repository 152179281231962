import { CollectionExpressionEntry } from "@biblioteksentralen/cordata";
import { Modify } from "@biblioteksentralen/js-utils/dist/typeUtils";

// TODO: Is there a better way to express "deep NonNullable"?
export type CollectionExpressionEntryWithWork = Modify<
  CollectionExpressionEntry,
  {
    collection: Modify<
      CollectionExpressionEntry["collection"],
      { workExpressed: NonNullable<CollectionExpressionEntry["collection"]["workExpressed"]> }
    >;
  }
>;

export const collectionExpressionEntryHasWork = (
  entry: CollectionExpressionEntry
): entry is CollectionExpressionEntryWithWork => !!entry.collection.workExpressed;
